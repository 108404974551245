<template>
  <div>
    <BaseModal
      :is-visible="applyRegistrationModalEnabled"
      :is-loading="isLoading"
      :handle-close="applyRegistrationModalToggle"
      custom-width="20%"
      title="Request Approval"
    >
      <div class="modal-body">
        <BaseComponent :is-loading="!pageHasLoaded" class="licenses-add-medical">
          <template v-if="pageHasLoaded" slot="content">
            <a-form layout="horizontal">
              <Select
                dropdown-title="DEA License"
                :options="deaLicenses"
                :handle-change="(val) => handleSelect(val, 'dea')"
                custom-input
                placeholder="Select DEA"
              />
              <Select
                dropdown-title="Medical License"
                :options="medicalLicenses"
                :handle-change="(val) => handleSelect(val, 'license')"
                custom-input
                placeholder="Select License"
              />
              <router-link
                v-if="locationLicensesEmpty"
                :to="{
                  path: `/dashboard/${getSelectedCurrentLocationId}/settings/licenses`,
                }"
                class="upload-license-btn"
              >
                Need New or Additional Licenses? Upload here
              </router-link>
            </a-form>
          </template>
        </BaseComponent>
        <BaseButton
          class="btn"
          type="primary"
          :is-loading="false"
          button-text="Submit"
          :is-disabled="requestApprovalBtnDisabled"
          :handle-click="handleRequestApprovalBtn"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { mapGetters, mapActions } from "vuex";
import BaseButton from "@/components/common/AntDesign/BaseButton.vue";
import BaseModal from "@/components/common/AntDesign/BaseModal.vue";
import Select from "@/components/shared-components/Select.vue";
import BaseComponent from "@/components/shared-components/BaseComponent.vue";

export default {
  name: "ApplyRegistrationModal",
  components: {
    BaseButton,
    BaseModal,
    Select,
    BaseComponent,
  },
  props: {
    applyRegistrationModalToggle: Function,
    applyRegistrationModalEnabled: Boolean,
    product: Object,
  },
  data() {
    return {
      deaLicenses: [],
      medicalLicenses: [],
      selectedDea: "",
      selectedMedical: "",
      isLoading: false,
      pageHasLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      getOrgId: "getOrgId",
      getSelectedCurrentLocationId: "getSelectedCurrentLocationId",
    }),
    ...mapGetters("usersModule", ["isLoggedIn"]),
    ...mapGetters("accountsModule", ["getDeaLicenses", "getMedicalLicenses"]),
    requestApprovalBtnDisabled() {
      return isEmpty(this.selectedDea) || isEmpty(this.selectedMedical);
    },
    locationLicensesEmpty() {
      return isEmpty(this.getDeaLicenses) || isEmpty(this.getMedicalLicenses);
    },
  },
  async created() {
    const orgId = localStorage.getItem("orgId");
    const locationId = localStorage.getItem("currentLocation");
    if (this.isLoggedIn) {
      const getLicensePayload = {
        orgId,
        locationId,
        queryParams: {
          status: "active",
        },
        initState: true,
      };
      await this.fetchMedicalLicenses(getLicensePayload);
      await this.fetchDeaLicenses(getLicensePayload);
      this.getDeaLicenses.forEach((license) => {
        this.deaLicenses.push({ name: license.licenseName, id: license.id });
      });
      this.getMedicalLicenses.forEach((license) => {
        this.medicalLicenses.push({ name: license.licenseName, id: license.id });
      });
    }
    this.pageHasLoaded = true;
  },
  methods: {
    ...mapActions({
      addVendorRegistration: "addVendorRegistration",
    }),
    ...mapActions("accountsModule", ["fetchDeaLicenses", "fetchMedicalLicenses"]),
    handleSelect(value, type) {
      if (type === "dea") {
        this.selectedDea = value;
      } else {
        this.selectedMedical = value;
      }
    },
    async handleRequestApprovalBtn() {
      this.isLoading = true;
      await this.addVendorRegistration({
        orgId: this.getOrgId,
        locationId: this.getSelectedCurrentLocationId,
        deaLicenseId: this.selectedDea,
        medicalLicenseId: this.selectedMedical,
        vendorId: this.product.vendorId,
      });
      this.applyRegistrationModalToggle();
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .loading {
    position: relative !important;
    width: 100%;
  }
}
.upload-license-btn {
  color: #f05a28;
  font-size: 11px;
}
</style>
