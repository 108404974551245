<template>
  <span>
    <el-tooltip
      v-if="!getUserLoggedInStatus"
      class="tooltip"
      effect="dark"
      content="You must be signed in to download attachments"
      placement="bottom"
    >
      <a class="file">
        {{ file.name }}
        <i class="el-icon-download" />
      </a>
    </el-tooltip>
    <a v-else class="file" @click="downloadAttachment">
      {{ file.name }}
      <i class="el-icon-download" />
    </a>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ProductAttachment",
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getUserLoggedInStatus"]),
  },
  methods: {
    ...mapActions("filesModule", ["fetchFileToDownload"]),
    downloadAttachment() {
      this.fetchFileToDownload(this.file);
    },
    getUrlExtension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
  },
};
</script>

<style lang="scss" scoped>
.file {
  display: inline-block;
  color: $simpatra-blue;
  background: $grey;
  font-size: 0.8em;
  font-weight: 600;
  padding: 10px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: $dark-grey;
  }
}
</style>
