<template>
  <div class="image-container">
    <div class="main-image-wrapper">
      <img :src="mainImage.imageLocation" :alt="productDetails.name" class="main-image" />
    </div>
    <div v-if="multipleImages" class="other-images">
      <div v-for="image in otherImages" :key="image.id" class="image-wrapper" @click="updateMainImage(image)">
        <img :src="image.imageLocation" :alt="productDetails.name" class="image" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductImages",
  props: {
    productDetails: Object,
  },
  data() {
    return {
      mainImage: {},
    };
  },
  computed: {
    multipleImages() {
      return this.productDetails.images.length > 1;
    },
    otherImages() {
      return this.productDetails.images.filter((i) => {
        return i.id !== this.mainImage.id;
      });
    },
  },
  watch: {
    productDetails() {
      this.mainImage = this.productDetails.images.find((i) => {
        return i.mainImage;
      });
    },
  },
  created() {
    this.mainImage = this.productDetails.images.find((i) => {
      return i.mainImage;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.mainImage = {};
    next();
  },
  methods: {
    updateMainImage(image) {
      this.mainImage = image;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.image-container {
  width: 100%;
}
.main-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  height: 400px;
  margin-bottom: 20px;
  box-shadow: $shadow-default;
}
.main-image {
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.other-images {
  display: flex;
  height: 100px;
}
.image-wrapper {
  flex: 1;
  overflow: hidden;
  max-width: 100px;
  border-bottom: 2px solid transparent;
  &:hover {
    cursor: pointer;
    filter: saturate(1.2);
    border-bottom: 2px solid $simpatra-orange;
  }
}
.image {
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
}
@media screen and (max-width: 920px) {
  .main-image-wrapper {
    height: auto;
  }
}
</style>
